import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["inputElement"]
  static values = { delay: Number, klass: String }

  // TODO: Make this more generic
  // Attention coupled with keysubmitter_controller.js for now

  addState(event) {
    if(this.hasInputElementTarget && this.inputElementTarget.value.length <= 0){
      setTimeout(() => {
        this.inputElementTarget.classList.add(this.klassValue || null);
      }, this.delayValue || 0)
    }else{
      this.inputElementTarget.classList.remove(this.klassValue || null)
    }
  }
  connect(){
    // Initiate with that state
    this.inputElementTarget.classList.add(this.klassValue)
  }

}

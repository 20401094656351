import {Controller} from "stimulus"

export default class extends Controller {

  static targets = ["activeElement"]
  static values = {activeClass: String}


  active(event){
      let mainTarget = null;
      if (!this.activeElementTargets.includes(event.target)) {
          mainTarget = event.target.parentNode;
      }
    this.activeElementTargets.forEach( (elem) => { elem.classList.remove(this.activeClassValue) } )

    if(event.target.dataset.activeElementId){
      document.getElementById(event.target.dataset.activeElementId).classList.add(this.activeClassValue);
    }else {
        (mainTarget || event.target).classList.add(this.activeClassValue);
    }
  }

}

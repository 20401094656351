import {Controller} from "stimulus"

export default class extends Controller {

  static targets = ["enableElement"]

  enableTarget(e){
    if(this.hasEnableElementTarget){
      this.enableElementTarget.disabled = false;
      this.enableElementTarget.classList.remove('disabled', 'pe-none');
    }
  }

}

import {Controller} from "stimulus"


export default class extends Controller {

  static targets = ["btnElement", "inputElement"]
  static values = {toggleClass: Array, toggleIcon: Array}

  state = 'initial';

  onInputChange() {
    if (this.inputElementTarget.value.length > 0 && this.state == 'initial') {
      this.changeState();
      this.state = 'changed'
    } else if (this.inputElementTarget.value.length <= 0 && this.state == 'changed') {
      this.revertState();
      this.state = 'initial'
    }
  }

  changeState() {
    this.btnElementTarget.classList.remove(this.toggleClassValue[0])
    this.btnElementTarget.classList.add(this.toggleClassValue[1])
    this.btnElementTarget.querySelector(this.toggleIconValue[0]).style.display = 'none'
    this.btnElementTarget.querySelector(this.toggleIconValue[1]).style.display = 'initial'
  }

  revertState() {
    this.btnElementTarget.classList.remove(this.toggleClassValue[1])
    this.btnElementTarget.classList.add(this.toggleClassValue[0])
    this.btnElementTarget.querySelector(this.toggleIconValue[1]).style.display = 'none'
    this.btnElementTarget.querySelector(this.toggleIconValue[0]).style.display = 'initial'
  }

  connect() {
    this.onInputChange(); // Set initial state
  }
}

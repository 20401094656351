import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["launchpadContainer"]
    static values = {scrollIntoView: String}


    connect() {
        // Fix issue with scrollIntoView not working after visit because of turbolinks
        // @see https://github.com/hotwired/turbo/issues/354
        // @see https://github.com/joemasilotti/railsdevs.com/pull/521
        // setTimeout(() => {
        //     const elem = this.launchpadContainerTarget.querySelector(this.scrollIntoViewValue)
        //     if (elem) {
        //         // window.scrollToElement(elem, 200, 100)
        //     }
        // }, 400)

        document.addEventListener('scroll', (event) => {
         document.body.dataset.userScrolled = true;
        }, {once: true})

    }
}

import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["ratingElement", "selectionElement"]
    static values = {defaultState: String}

    showRatingElement(event) {
        if (this.hasRatingElementTarget && this.defaultStateValue !== 'hidden') {
            // @see https://stimulus.hotwired.dev/reference/controllers#cross-controller-coordination-with-events
            setTimeout(()=> {
                this.dispatch("lilata:mobile-app-messenger-event", {prefix: false, detail: {data: {type: 'hide_tab_bar'}}})
            }, 400)
            this.showElement(this.ratingElementTarget)
        }
    }

    hideRating(event) {
        event.preventDefault();
        // @see https://stimulus.hotwired.dev/reference/controllers#cross-controller-coordination-with-events
        this.dispatch("lilata:mobile-app-messenger-event", {prefix: false, detail: {data: {type: 'show_tab_bar'}}})
        this.hideElement(this.ratingElementTarget)
    }

    hideRatingWithLoader(event) {
        event.preventDefault();
        // @see https://stimulus.hotwired.dev/reference/controllers#cross-controller-coordination-with-events
        this.dispatch("lilata:mobile-app-messenger-event", {prefix: false, detail: {data: {type: 'show_tab_bar'}}})
        this.hideElement(document.getElementById('dialog-rating-inner'))
        this.showElement(document.getElementById('loading-container'))
        this.updateReviewInteraction();
        setTimeout(() => this.hideElement(this.ratingElementTarget), 4000) // Hide after 4 seconds
    }


    showThanksForResponse() {
        this.hideElement(document.getElementById('title-message-initial'))
        this.hideElement(document.getElementById('rating-options'))
        this.showElement(document.getElementById('title-message-thank-you-for-response'))
        this.showElement(document.getElementById('close-rating-btn'))
        this.showElement(document.getElementById('write-review-btn'))
    }


    hideElement(e) {
        e.classList.add('d-none')
    }

    showElement(e) {
        if (e) {
            e.classList.remove('d-none')
        }
    }

    handleBackDropClick(event) {
        if (event && event.target.classList.contains('dialog__backdrop')) {
           this.hideRating(event)
        }
    }

    updateReviewInteraction(){
        fetch('/profile/review', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            },
            body: null,
        })
            .then((response) => response.text())
            .then((data) => {
                // console.log('Success:', data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    connect() {
        this.showRatingElement();
        document.addEventListener('click', this.handleBackDropClick.bind(this))
    }
}

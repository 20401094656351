import {Controller} from "stimulus"

export default class extends Controller {


  disableElements(e){
    const elements = document.querySelectorAll('[data-ajax-disable="true"]');
    elements.forEach(element => {
      element.disabled = true;
      element.classList.add('disabled');
    })
  }

}

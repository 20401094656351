import {Controller} from "stimulus"

export default class extends Controller {
  static values = {fragment: String}

  appendFragment() {
    window.location.hash = this.fragmentValue
  }

  connect(){
    if(this.hasFragmentValue){
      window.location.hash = this.fragmentValue
    }
  }
}

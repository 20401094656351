import {Controller} from "stimulus"


export default class extends Controller {

    addLoader(event) {
        event.target.classList.add('position-relative', 'disabled');
        setTimeout(() => {
            event.target.dataset.originalText = event.target.innerText;
            event.target.innerText = '';
            event.target.classList.add('spinner');
        }, 1000)
        setTimeout(() => {
            event.target.disabled = true
        }, 100);
    }
}




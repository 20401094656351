import Scrollbar, {ScrollbarPlugin} from "smooth-scrollbar";

export default class StopScrollPlugin extends ScrollbarPlugin {
    static pluginName = "stopScroll"
    static defaultOptions = {
        stopScroll: false
    }

    transformDelta(delta) {
        return this.options.stopScroll ? {x: 0, y: 0} : delta;
    }
}

const defaultSettings = {
    damping: 0.2,
    alwaysShowTracks: true,
};

function scrollIntoView(target) {
    Scrollbar.getAll().forEach((scrollbar) => {
        scrollbar.scrollIntoView(target, {
            offsetTop: 30,
            onlyScrollIfNeeded: true,
        });
    });
}

function updateScrollbars() {
    setTimeout(function () {
        Scrollbar.getAll().forEach((scrollbar) => {
            scrollbar.update();
        });
    }, 250);
}

function destroyScrollbars() {
    Scrollbar.destroyAll();
}

function stopScroll(target) {
    const scrollbar = Scrollbar.get(target);
    if (scrollbar) {
        scrollbar.updatePluginOptions("stopScroll", {stopScroll: true});
    }
}

function resumeScroll(target) {
    const scrollbar = Scrollbar.get(target);
    if (scrollbar) {
        scrollbar.updatePluginOptions("stopScroll", {stopScroll: false});
    }
}

function buildScrollbars(target) {
    Scrollbar.use(StopScrollPlugin);

    let scrollbar = null;
    const options = {...defaultSettings, ...{ delegateTo: document.body}};
    if (target) {
        scrollbar = Scrollbar.init(target, options);
    } else {
        const scrollbars = Scrollbar.initAll(options);
        scrollbar = scrollbars[0]
    }

    return scrollbar
}

export {buildScrollbars, scrollIntoView, destroyScrollbars, updateScrollbars, stopScroll, resumeScroll}
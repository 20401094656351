import {Controller} from "stimulus"

export default class extends Controller {

  static values = {
    containerClass: String,
    vendorId: Number,
    productId: Number,
    sandbox: Boolean,
    userEmail: String,
    passthrough: String,
    cancelUrl: String,
    redirectUrl: String,
    locale: String
  }

  connect() {
    if (this.sandboxValue) {
      Paddle.Environment.set('sandbox');

    }
    if(!this.hasCancelUrlValue){
      this.paddleCheckoutPurchase();
    }
  }

  paddleCheckoutCancel(e){
    e.preventDefault();

    Paddle.Setup({
      vendor: this.vendorIdValue // Replace with your Vendor ID.
    });
    Paddle.Checkout.open({
      override: this.cancelUrlValue,
      success: this.redirectUrlValue
    });
  }

  paddleCheckoutPurchase(){
    Paddle.Setup({
      vendor: this.vendorIdValue // Replace with your Vendor ID.
    });
    Paddle.Checkout.open({
      method: 'inline',
      product: this.productIdValue,// Replace with your Product or Plan ID
      email: this.userEmailValue,
      success: this.redirectUrlValue,
      locale: this.localeValue || 'en',
      allowQuantity: false,
      disableLogout: true,
      passthrough: this.passthroughValue,
      frameTarget: this.containerClassValue, // The className of your checkout <div>
      frameInitialHeight: 478,
      frameStyle: 'width:100%; min-width:312px; background-color: transparent; border: none;',  // Please ensure the minimum width is kept at or above 286px with checkout padding disabled, or 312px with checkout padding enabled. See "General" section under "Branded Inline Checkout" below for more information on checkout padding.
    });
  }
}

import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["submittableElement"]

  keySubmit(event) {
    if (this.hasSubmittableElementTarget && this.initiatedEnterKeyPress(event) && this.validAction(event)) {
      this.submittableElementTarget.click();
    }
  }

  validAction(event) {
    if (!this.submittableElementTarget.dataset.keysubmitterRule) {
      return true;
    }

    const keyNames = this.submittableElementTarget.dataset.keysubmitterKeys.split(',');

    let result = false;
    const [rule, elementId] = this.submittableElementTarget.dataset.keysubmitterRule.split('|')
    const element = document.getElementById(elementId);

    if (rule === 'blank') {
      const text = (element.text || element.value).trim();
      if ((keyNames.includes('delete') || keyNames.includes('backspace')) && element.classList.contains('lilata-deletable')) {
        result = !text || text.length === 0;
      }
    }

    return result;
  }

  initiatedEnterKeyPress(event) {
    const keyNames = this.submittableElementTarget.dataset.keysubmitterKeys.split(',');


    return (event.code !== undefined || event.key !== undefined) &&
      (keyNames.includes(event.code.toLowerCase) || keyNames.includes(event.key.toLowerCase()))
  }

  connect() {
  }
}

import {Controller} from "stimulus"

let delayedTimeouts = []

export default class extends Controller {

    // static targets = ["audioElement", "audioIcon"]
    static targets = ["audioElement"]
    static values = {volume: Number, autoplay: Boolean, delay: Number, offset: Number}

    listen() {
        const audio = this.audioElementTarget;
        if(audio.dataset.disabled){
            return;
        }
        if(audio.duration > 0 && audio.paused) {
            audio.volume = this.volumeValue || 0.4;
            audio.load(); // Fixes an audio clipping bug in Safari on iOS
            audio.currentTime = this.offsetValue || 0;
            const timeout = setTimeout(() => audio.play(), this.delayValue || 0)
            delayedTimeouts.push(timeout)
        }
    }

    listenSlow() {
        const audio = this.audioElementTarget;
        const playbackRate = audio.dataset.playbackRate == 0.7 ? 0.5 : 0.7
        audio.volume = this.volumeValue || 0.4;
        audio.load(); // Fixes an audio clipping bug in Safari on iOS
        audio.playbackRate = playbackRate;
        audio.dataset.playbackRate = playbackRate;
        const timeout = setTimeout(() => audio.play(), this.delayValue || 0)
        delayedTimeouts.push(timeout)

    }

    connect() {
        if (this.hasAudioElementTarget) {
            this.audioElementTarget.volume = this.volumeValue || 0.4;

            let cssKlasses = ['animate__animated', 'pulse', 'animate__infinite', 'animate__200ms']

            this.audioElementTarget.addEventListener('playing', function (e) {
                if (e.target.parentNode.classList.contains('icon-listening-exercise')){
                    e.target.parentNode.querySelector('img').classList.add(...cssKlasses);
                }
                else if(e.target.parentNode.classList.contains('audio-listener')) {
                    e.target.parentNode.classList.add(...cssKlasses);

                }
            }, false);
            this.audioElementTarget.addEventListener('ended', function (e) {
                if (e.target.parentNode.classList.contains('icon-listening-exercise')){
                    e.target.parentNode.querySelector('img').classList.remove(...cssKlasses);
                }
                else if (e.target.parentNode.classList.contains('audio-listener')) {
                    e.target.parentNode.classList.remove(...cssKlasses);

                }
            }, false);

            if (this.autoplayValue) {
                const timeout = setTimeout(() => this.audioElementTarget.play(), this.delayValue || 0)
                delayedTimeouts.push(timeout)

            }

            document.addEventListener("ajax:beforeSend", () => {
                delayedTimeouts.forEach((t) => {
                    clearTimeout(t);
                    delayedTimeouts = [];
                })
                this.audioElementTarget.pause()
                this.audioElementTarget.currentTime = 0;
                this.audioElementTarget.dispatchEvent(new Event('ended'));
            })
        }
    }
}

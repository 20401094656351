import {Controller} from "stimulus"
import {DateTime} from "luxon";

export default class extends Controller {
    static targets = ["selectElement"]

    connect() {
        if (this.hasSelectElementTarget) {
            let dt = DateTime.now().plus({minutes: 30}).startOf('hour');
            const formattedTime = dt.toLocaleString(DateTime.TIME_24_SIMPLE);
            this.selectElementTarget.value = formattedTime;
        }
    }
}

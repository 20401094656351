import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["timezoneInput"]

  connect() {
    if(this.hasTimezoneInputTarget){
      this.timezoneInputTarget.value = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
  }
}

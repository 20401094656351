import {Controller} from "stimulus"
import * as confetti from 'canvas-confetti';

export default class extends Controller {

    static targets = ['confettiContainer']

    confettiContainerTargetConnected(e) {
        const myCanvas = document.createElement('canvas');
        myCanvas.style.position = 'fixed';
        myCanvas.style.top = 0;
        myCanvas.style.left = 0;
        myCanvas.style.pointerEvents = 'none';
        myCanvas.style.zIndex = 100;
        myCanvas.style.width = '348px';
        myCanvas.style.height = '800px';

        this.confettiContainerTarget.appendChild(myCanvas);

        myCanvas.confetti = confetti.create(myCanvas, {
            resize: true,
            useWorker: false,
            shapes: ['circle', 'circle', 'square'],

        })

        myCanvas.confetti({
            startVelocity: 25,
            spread: 80,
            angle: 270,
            origin: {y: -0.1, x: 0.5},
            colors: ['#6d37e7', '#814dc9', '#8979d5', '#ead03a', '#fbb1bd', '#f9bec7']
        })
    }

    randomInRange(min, max) {
        return Math.random() * (max - min) + min;
    }
}

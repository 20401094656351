import {Controller} from "stimulus"
// import {stopScroll, resumeScroll} from "../js/scrollbar-utils";

export default class extends Controller {

    static targets = ["container"]
    spinnerLoader = "<div class='d-block w-100 mt-5 text-center animate__animated animate__fadeIn animate__delay-1s'>" +
        "<div class='spinner-border text-black-40' role='status'>" +
        "<span class='sr-only'>Loading...</span>" +
        "</div></div>"

    open(event) {
        // Build and stop scroll on parent container
        const modal = this.buildModal();
        this.containerTarget.append(modal);
        stopScroll(this.containerTarget);


        // Do stuff when animation ends
        modal.addEventListener('animationend', function () {
            if (modal.classList.contains('animate__fadeOutDown')) {
                modal.remove(); // On fadeout down animation completion remove modal element from DOM
            }
        });
    }

    buildModal() {
        // Close btn
        const closeBtn = document.createElement("button");
        closeBtn.id = 'modal-close'
        closeBtn.classList.add('btn', 'close-btn')

        // Content
        const contentDiv = document.createElement("div");
        contentDiv.id = 'lilata-inner-modal-content'
        contentDiv.classList.add('content')

        // Main container
        const mainDiv = document.createElement("div");
        mainDiv.id = 'lilata-inner-modal-container'
        mainDiv.classList.add('lilata-inner-modal-container', 'animate__animated', 'animate__fadeInUp')

        // Append elements
        mainDiv.append(closeBtn)
        mainDiv.append(contentDiv)
        contentDiv.innerHTML = this.spinnerLoader; // Add spinner to content

        const parentContainer = this.containerTarget
        closeBtn.onclick = function (e) {
            mainDiv.classList.add('animate__animated', 'animate__fadeOutDown')
            resumeScroll(parentContainer); // Resume scrolling
        }

        return mainDiv;
    }

}

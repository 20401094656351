import {Controller} from "stimulus"

export default class extends Controller {
    static get targets() {
        return ["hideOnFocusElement"];
    }

    onFocus() {
        for (const element of this.hideOnFocusElementTargets) {
            element.style.display = 'none';
        }
    }

    onBlur() {
        // Delay the on blur event for 50ms to make sure
        // any click event on another element
        // that is affected by the layout shift gets registered
        // @see https://stackoverflow.com/a/59119609
        setTimeout(() => {
            for (const element of this.hideOnFocusElementTargets) {
                element.style.display = null;
            }
        }, 50);
    }
}

import {Controller} from "stimulus"

export default class extends Controller {

    connect() {
        window.addEventListener("message", message => {
            if (window && window.ReactNativeWebView) {
                if (message.data === 'modal-close' || message.data === 'display-lesson-alert') {
                    document.getElementById(message.data)?.click();
                }
            }
        });
    }
}

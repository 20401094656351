import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["focusElement"]

  focus() {
    if (this.hasFocusElementTarget) {
      this.focusElementTarget.focus();
    }
  }

  connect() {
    if (this.hasFocusElementTarget) {
      // Revert autoscrolling of the page on input focus on iOS
      function scrollUp() {
        setTimeout(function() {
          window.scrollTo({
            top: 0,
            behavior: "smooth"
          });
        }, 400);
      }

      // Increase height on tall phones
      if (window.matchMedia("(max-width: 767px)").matches && window.matchMedia("(min-height: 651px)").matches) {
        $('body').addClass('mobile-tall');
      }

      this.focus();
      scrollUp();

      if ($('#translation-input').length) {
        $('#translation-input').on('focus', function() {
          scrollUp();
        });
        $('#translation-input').on('click', function() {
          scrollUp();
        });
      }

    }
  }

}

import {Controller} from "stimulus"


export default class extends Controller {

    static targets = ["btnElement"]
    static values = {toggleText: Array}

    state = 'initial';

    onClick() {
        if (this.state == 'initial') {
            this.changeState();
            this.state = 'changed'
        } else if (this.state == 'changed') {
            this.revertState();
            this.state = 'initial'
        }
    }

    changeTargetText(e) {
        if (e.target.dataset?.targetText) {
            this.btnElementTarget.innerText = e.target.dataset.targetText;
        }
    }

    changeState() {
        this.btnElementTarget.innerText = this.toggleTextValue[1]
    }

    revertState() {
        this.btnElementTarget.innerText = this.toggleTextValue[0]
    }

    connect() {
    }
}

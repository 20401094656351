import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["alertElement", "selectionElement"]
    static values = {defaultState: String, alertSectionId: String, delayMs: Number}

    showAlertElement(event) {
        // Handle alert depended on selection
        if (this.hasSelectionElementTarget && !this.selectionElementTarget.classList.contains(this.selectedClass) && window.innerWidth > 767) {
            event.preventDefault();
            event.stopImmediatePropagation();
            this.showElement(this.alertElementTarget)
        } else if (this.hasAlertElementTarget && !this.hasSelectionElementTarget) {
            event.preventDefault();
            event.stopImmediatePropagation();

            setTimeout(() => {
                this.showElement(this.alertElementTarget)

            }, this.delayMsValue || 0)
            this.dispatch("lilata:mobile-app-messenger-event", {prefix: false, detail: {data: {type: 'hide_tab_bar'}}})
            this.displayedSectionId = event.target.dataset.alertSectionId || event.target.parentNode.closest('a')?.dataset?.alertSectionId;
            this.hiddenBtnIds = event.target.dataset.hideAlertButtons || event.target.parentNode.closest('a')?.dataset?.hideAlertButtons;
            document.body.style.overflow = 'hidden';
            if (this.displayedSectionId) {
                this.showElement(document.getElementById(this.displayedSectionId))
            }
            if (this.hiddenBtnIds) {
                this.hiddenBtnIds.split(',').forEach((id) => this.hideElement(document.getElementById(id))
                )
            }
        }
    }

    // Check if all characters are selected from the container.

    checkSelection(event) {
        if (window.getSelection().toString().trim().length > 0) {
            this.selectionElementTarget.classList.add(this.selectedClass);
        }
    }

    hideAlert(event) {
        document.body.style.overflow = 'visible';
        this.hideElement(this.alertElementTarget)

        if (this.displayedSectionId) {
            if(this.displayedSectionId === 'complete_previous_topics'){
                this.dispatch("lilata:mobile-app-messenger-event", {prefix: false, detail: {data: {type: 'show_tab_bar'}}})
            }
            this.hideElement(document.getElementById(this.displayedSectionId))
            this.resetInitialElements();
            this.displayedSectionId = null
            this.hiddenBtnIds = null;
            return
        }

        if (event.target.dataset.sections) {
            event.target.dataset.sections.split(',').forEach((e) => this.hideElement(e))
        }
    }

    hideElement(e) {
        if (e.dataset.hideAnimation) {
            e.dataset.hideAnimation.split(' ').forEach((klass) => e.classList.add(klass))
        } else {
            e.classList.add('d-none')
        }
    }

    showElement(e) {
        e.classList.remove('d-none')
    }

    resetInitialElements() {
        if (this.hiddenBtnIds) {
            this.hiddenBtnIds.split(',').forEach((id) => this.showElement(document.getElementById(id))
            )
        }
    }

    displayMobileTabBar() {
        this.dispatch("lilata:mobile-app-messenger-event", {prefix: false, detail: {data: {type: 'show_tab_bar'}}})
    }

    handleBackDropClick(event) {
        if (event && event.target.classList.contains('dialog__backdrop')) {
            this.hideAlert(event)
        }
    }

    // Ugly hack to play audio after the listen and repeat instructions are show
    // TODO: Extract this to a different controller
    playAudio(){
        document.querySelectorAll('audio').forEach((audio) => {
            if(audio.duration != 0 && audio.paused){
                audio.play()
            }
        })
    }

    connect() {
        if (this.hasAlertElementTarget && this.hasDefaultStateValue && this.defaultStateValue == 'open') {
            // console.log(this.alertElementTarget, this.defaultStateValue)
            this.showAlertElement();
        }

        document.addEventListener('click', this.handleBackDropClick.bind(this))

        // Autoplay onboarding video only on desktop to avoid a full-screen autoplay bug on iOS
        if ($('#pin-extension-video').length && !window.matchMedia("(max-width: 767px)").matches) {
            $('#pin-extension-video')[0].play();
        }

        this.selectedClass = 'selected';
        this.displayedSectionId = null;
        this.hiddenBtnIds = null;

    }
}

import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["surveyElement"]
    static values = {defaultState: String}

    showSurveyElement(event) {
        if (this.hasSurveyElementTarget && this.defaultStateValue !== 'hidden') {
            this.showElement(this.surveyElementTarget)
        }
    }

    hideSurvey(event) {
        event.preventDefault();
        this.hideElement(this.surveyElementTarget)
    }

    hideSurveyWithLoader(event) {
        event.preventDefault();
        // @see https://stimulus.hotwired.dev/reference/controllers#cross-controller-coordination-with-events
        setTimeout(()=> {
            this.dispatch("lilata:mobile-app-messenger-event", {prefix: false, detail: {data: {type: 'in_app_browser', url: 'https://calendar.app.google/d1ASsHf3FNXDJhgz6'}}})
        }, 400)
        this.hideElement(document.getElementById('dialog-rating-inner'))
        this.showElement(document.getElementById('loading-container'))
        setTimeout(() => this.hideElement(this.surveyElementTarget), 4000) // Hide after 4 seconds
    }


    hideElement(e) {
        e.classList.add('d-none')
    }

    showElement(e) {
        if (e) {
            e.classList.remove('d-none')
        }
    }

    connect() {
        this.showSurveyElement();
    }
}

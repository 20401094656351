import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ['dailyGoalCalculatedElement', 'dailyGoalCalculateWordsElement', 'dailyGoalContainerElement']

  // Accepts minutes of daily goal and calculates hours
  calculateDailyGoal(e){
    const hours = (e.target.value * 30 / 60)
    this.dailyGoalContainerElementTarget.classList.remove('d-none')
    this.dailyGoalCalculatedElementTarget.textContent = hours;
  }

  calculateDailyGoalWords(e){
    const words = e.target.value * 30
    this.dailyGoalContainerElementTarget.classList.remove('d-none')
    this.dailyGoalCalculateWordsElementTarget.textContent = words;
  }
}

function scrollToElement(element, duration = 500, offset = 100) {
    const options = {
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
    };

    const boundingRect = element.getBoundingClientRect();
    const targetY = window.scrollY + boundingRect.top - window.innerHeight / 2 + boundingRect.height / 2 + offset;
    const startingY = window.scrollY;
    const diffY = targetY - startingY;

    let startTime = null;

    function scroll(currentTime) {
        if (!startTime) {
            startTime = currentTime;
        }

        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1);

        const scrollY = startingY + diffY * progress;
        window.scrollTo(0, scrollY);

        if (elapsed < duration) {
            requestAnimationFrame(scroll);
        }
    }

    requestAnimationFrame(scroll);
}

// Attach the scrollToElement function to the global object
window.scrollToElement = scrollToElement;

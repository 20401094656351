import {Controller} from "stimulus"
// import {buildScrollbars, destroyScrollbars, scrollIntoView, updateScrollbars} from "../js/scrollbar-utils";

export default class extends Controller {

    static targets = ["scrollContainer", "scrollIntoView"]
    static values = {
        keepPrevious: Boolean,
    }

    settings = {
        damping: 0.2,
        alwaysShowTracks: true,
        wheelEventTarget: document.body
    };

    scrollIntoView() {
        scrollIntoView(this.scrollIntoViewTarget)
    }

    updateScrollbar() {
        // updateScrollbars()
    }

    destroyScrollbars() {
        // destroyScrollbars()
    }


    connect() {
        if (!this.keepPreviousValue) {
            // destroyScrollbars();
        }
        // buildScrollbars(this.scrollContainerTarget);
    }
}

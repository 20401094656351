import {Controller} from "stimulus"
import {decode} from 'blurhash'

export default class extends Controller {

    static targets = ["imgElement"]
    static values = {hash: String}
    intervalId = null;

    // canvasElementTargetConnected(target) {
    //     if (this.hashValue) {
    //         const pixels = decode(this.hashValue, 32, 32)
    //         const imageData = new ImageData(pixels, 32, 32)
    //         const context = this.canvasElementTarget.getContext('2d')
    //         context.putImageData(imageData, 0, 0)
    //     }
    // }

    imgElementTargetConnected(target) {
        // this.imgElementTarget.addEventListener('transitionend', this.imgTransitionEnd.bind(this))
        this.imgElementTarget.onload = () => {
            this.imgElementTarget.style.zindex = 1;
            this.imgElementTarget.classList.add('fadein')
            clearInterval(this.intervalId)
        }
        this.checkIfImageIsLoaded(200)
    }

    checkIfImageIsLoaded(ms = 200) {
        this.intervalId = setInterval(() => {
            if (this.imgElementTarget.complete) {
                this.imgElementTarget.style.zindex = 1;
                this.imgElementTarget.classList.add('fadein')
                // this.canvasElementTarget.style.zindex = -1;
                clearInterval(this.intervalId)
            }
        }, ms)
    }

    imgTransitionEnd(event) {
        // Check if the transition that just ended is the fade-in transition
        if (event.propertyName === 'opacity') {
            // hide canvas if image has completely faded in
            // this.canvasElementTarget.style.zindex = -1;
        }
    }

    disconnect() {
        if (this.intervalId) {
            clearInterval(this.intervalId)
        }
    }

}

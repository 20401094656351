import Rails from "@rails/ujs";
import {Controller} from "stimulus";

export default class extends Controller {
    onPostSuccess(event) {
        console.log("success!");
    }

    requestInProgress(event) {
        const inputModelName = event.target.dataset.model || 'quiz'
        const inputs = [...event.target.querySelectorAll(`input[name=\"${inputModelName}[translation]\"]`)]
        inputs.forEach(input => {
            input.removeAttribute('data-action')
            if(!input.parentElement.classList.contains('selected')){
                input.parentElement.classList.add('disabled')
            }
        })
    }

    requestError() {

    }

    update(event) {
        event.target.parentElement.classList.add('selected', 'active')
        Rails.fire(this.element, 'submit');

    }
}

import {Controller} from "stimulus"

export default class extends Controller {

  connect() {
    // Initial Bootstrap Tooltip
    // Tooltip for non-clickable elements
    if ($('[data-toggle="tooltip"]').length) {
      $('[data-toggle="tooltip"]').tooltip({
        trigger: 'hover focus',
        delay: {
          show: 300,
          hide: 0
        }
      });
      
      $(document).scroll(function() {
        $('[data-toggle="tooltip"]').tooltip('hide');
      });
    }
    
    // Tooltip for clickable elements (hides on click)
    if ($('[data-toggle="tooltip-clickable"]').length) {
      $('[data-toggle="tooltip-clickable"]').tooltip({
        trigger: 'hover',
        delay: {
          show: 300,
          hide: 0
        }
      });

      var isMobile = window.matchMedia("(max-width: 767px)").matches;
      if (isMobile) {
        $('[data-toggle="tooltip-clickable"]').on('mouseover', function() {
          $('[data-toggle="tooltip-clickable"]').tooltip('hide');
        });
      }

      $('[data-toggle="tooltip-clickable"]').on('click', function() {
        $('[data-toggle="tooltip-clickable"]').tooltip('hide');
      });
    }

  }
}

import {Controller} from "stimulus"

export default class extends Controller {

    static targets = ["loadingElement"]
    static values = {loaderType: String}

    bigLoader = "<div class='spinner-grow spinner-grow-sm text-secondary mr-2' role='status'>"
        + "<span class='sr-only'>Loading...</span>"
        + "</div>"
        + "<div class='spinner-grow spinner-grow-sm text-secondary mr-2' role='status'></div>"
        + "<div class='spinner-grow spinner-grow-sm text-secondary' role='status'></div>";

    smallLoader = "<div class='spinner-grow spinner-grow-sm text-secondary role='status'>"
        + "<span class='sr-only'>Loading...</span>"
        + "</div>";

    spinnerLoader = "<div class='d-block w-100 mt-5 text-center'><div class='spinner-border text-black-40' role='status'>" +
        "<span class='sr-only'>Loading...</span>" +
        "</div></div>"

    addLoader() {
        const target = this.loadingElementTarget;
        target.disabled = true;
        target.classList.add('disabled');
        switch (this.loaderTypeValue) {
            case 'big':
                target.innerHTML = this.bigLoader;
                break;
            case 'small':
                target.innerHTML = this.smallLoader;
                break;
            case 'spinner':
                target.innerHTML = this.spinnerLoader;
                break;
            default:
                target.innerHTML = this.smallLoader;
                break;
        }
    }

    connect() {
    }
}

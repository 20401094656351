import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["streakPausedElement"]
    static values = {defaultState: String}

    showStreakPausedElement(event) {
        if (this.hasStreakPausedElementTarget && this.defaultStateValue !== 'hidden') {
            // @see https://stimulus.hotwired.dev/reference/controllers#cross-controller-coordination-with-events
            setTimeout(()=> {
                this.dispatch("lilata:mobile-app-messenger-event", {prefix: false, detail: {data: {type: 'hide_tab_bar'}}})
                this.dispatch("lilata:mobile-app-messenger-event", {prefix: false, detail: {data: {type: 'analytics_event', event_name: 'streak_paused_dialog_show'}}})
            }, 400)
            this.showElement(this.streakPausedElementTarget)
        }
    }

    hideStreakPausedElement(event) {
        event.preventDefault();
        // @see https://stimulus.hotwired.dev/reference/controllers#cross-controller-coordination-with-events
        this.dispatch("lilata:mobile-app-messenger-event", {prefix: false, detail: {data: {type: 'show_tab_bar'}}})
        this.hideElement(this.streakPausedElementTarget)
    }

    hideElement(e) {
        e.classList.add('d-none')
    }

    showElement(e) {
        if (e) {
            e.classList.remove('d-none')
        }
    }

    connect() {
        console.log('connected streak paused')
        this.showStreakPausedElement();
    }
}

import {Controller} from "stimulus"

export default class extends Controller {
    static values = {showElementIds: Array, hideElementIds: Array}


    resetState() {
        this.showElementIdsValue.forEach((id) => {
            document.getElementById(id).classList.remove('d-none')
        });
        this.hideElementIdsValue.forEach((id) => {
            document.getElementById(id).classList.add('d-none')
        });
        document.querySelectorAll('input').forEach((input) => {
            input.disabled = false;
            input.checked = false;
        })
        // For listen and repeat exercise we expect an object with learnableId
        if (document.getElementById('learnable')) {
            document.getElementById('learnable').querySelectorAll("[data-word]").forEach((elem) => elem.classList.remove('text-secondary'));
            if(document.querySelector('.audio-listener')){
                let audioController = this.application.getControllerForElementAndIdentifier(document.querySelector('.audio-listener'), "audio")
                audioController.listen();
            }
        }
    }

}

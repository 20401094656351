import {Controller} from "stimulus"
import {boolean} from "smooth-scrollbar/decorators";

export default class extends Controller {

    static values = {action: Object, dontPreventDefault: Boolean, disableSeconds: 0}

    async sendMessage(event = null) {
        if (window && this.hasActionValue) {

            if(this.hasDisableSecondsValue && this.disableSecondsValue > 0){
                this.disableForSeconds(event)
            }

            if(!this.dontPreventDefaultValue){
                event?.preventDefault();
            }

            const messageObj = this.actionValue;
            if (messageObj.type === 'page_loaded') {
                messageObj.url = window.location.href
                messageObj.path = window.location.pathname
            }

            const msg = await JSON.stringify(messageObj)

            setTimeout(function () {
                window.ReactNativeWebView?.postMessage(msg);
            }, 0)
        }
    }

    // @see https://stimulus.hotwired.dev/reference/controllers#cross-controller-coordination-with-events
    async sendMessageByEvent(event) {
        if (event?.detail) {
            const msg = await JSON.stringify(event.detail.data)

            setTimeout(function () {
                window.ReactNativeWebView?.postMessage(msg);
            }, 0)
        }
    }

    disableForSeconds(event){
        event.target.classList.add('position-relative', 'disabled');
        event.target.dataset.originalText = event.target.innerText;
        event.target.innerText = '';
        event.target.classList.add('spinner');
        setTimeout(() => {
            event.target.disabled = true
        }, 100);
        setTimeout(() => {
            event.target.classList.remove('position-relative', 'disabled');
            event.target.innerText = event.target.dataset.originalText;
            event.target.classList.remove('spinner');
        }, this.disableSecondsValue * 1000)
    }

    connect() {
    }
}
